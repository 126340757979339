import styled, { css } from "styled-components";

import media from "constants/media";

import Button from "components/ui/button/Button";
import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    ${media.mdUp} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const BackgroundImage = styled.div`
    display: flex;
    justify-content: center;
    padding: 100px 0;

    background: url(${({ imgMobile }) => imgMobile}) no-repeat center;
    background-size: cover;

    ${media.mdUp} {
        height: 600px;
        padding: 100px 0 0;

        background-image: url(${({ imgDesktop }) => imgDesktop});
    }

    ${media.lgUp} {
        height: 665px;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    margin: 0 auto 45px;

    ${media.mdUp} {
        align-items: flex-start;
        max-width: 50%;
        margin: 0;
    }
`;

export const Title = styled.h1`
    max-width: 300px;
    margin: 0 auto 40px;
    padding-top: 0;

    color: var(--color-white);
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;

    ${media.mdUp} {
        max-width: 100%;
        margin: 0 0 20px;

        font-size: 45px;
        line-height: 50px;
        text-align: left;
    }

    ${media.lgUp} {
        font-size: 55px;
        line-height: 80px;
    }
`;

export const StyledButton = styled(Button)`
    ${({ isChristmas }) =>
        isChristmas &&
        css`
            position: relative;

            ::after {
                position: absolute;
                top: -25px;
                right: -5px;

                display: inline-block;
                width: 54px;
                height: 62px;

                background: url("/images/img-christmas-hat.png") no-repeat;
                background-size: cover;

                content: "";
            }
        `}
`;

export const Card = styled.div`
    position: relative;

    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    max-width: 320px;
    margin: 0 auto;

    ${media.mdUp} {
        max-width: 40%;
        margin: 0 40px 0 0;
    }

    ${media.lgUp} {
        max-width: 400px;
        margin: 0 55px 0 0;
    }

    ${media.xlUp} {
        max-width: 444px;
    }
`;
