import styled, { css } from "styled-components";
import { a } from "@react-spring/web";

import media from "constants/media";

export const Container = styled.div`
    position: absolute;

    width: 100%;
    height: 100%;
`;

export const ImageContainer = styled(a.div)`
    position: absolute;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;

    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;

    ${media.mdUp} {
        ${({ type }) =>
            type === "card" &&
            css`
                border-radius: 15px;
                box-shadow: 0 10px 25px 0 rgba(49, 49, 62, 0.26);
            `}
    }
`;
