import styled, { css } from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    padding: 0 20px;

    ${({ position }) =>
        position === "top" &&
        css`
            margin: 40px auto;
        `}

    ${({ position }) =>
        position === "bottom" &&
        css`
            margin: 0 auto 40px;
        `}

    ${media.mdUp} {
        max-width: 780px;
        padding: 0;

        ${({ position }) =>
            position === "top" &&
            css`
                margin: 85px auto;
            `}

        ${({ position }) =>
            position === "bottom" &&
            css`
                margin: 0 auto 90px;
            `}

        ${({ clamp }) =>
            clamp &&
            css`
                text-align: center;
            `}
    }
`;

export const Title = styled.h2`
    margin: 0 auto 20px;

    font-size: var(--font-size-h3);
    line-height: var(--line-height-h4);

    ${media.mdUp} {
        line-height: 45px;
    }
`;

export const Content = styled.div`
    margin: 0 auto;

    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    p {
        margin: 0 0 10px;
    }
`;
