import Link from "next/link";
import PropTypes from "prop-types";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import ArrowLink from "components/ui/arrowLink/ArrowLink";

import ImagesDesktop from "./images/desktop/ImagesDesktop";
import ImagesMobile from "./images/mobile/ImagesMobile";

import {
    Container,
    Wrapper,
    Text,
    Title,
    Usps,
    Usp,
    Subtitle,
    StyledButton
} from "./Business.styled";

function Business({ title, description, images, link, blocks }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Wrapper>
            <Container>
                <Title>{title}</Title>
                <Text>{description}</Text>
                {mobileView && <ImagesMobile items={images} />}
                {blocks && (
                    <Usps>
                        {blocks.map(({ title, description, link }, index) => (
                            <Usp key={`lp-business-usps-${index}-${title}`}>
                                <Subtitle>{title}</Subtitle>
                                <Text>{description}</Text>
                                {link?.url && (
                                    <ArrowLink
                                        url={link.url}
                                        label={link.label}
                                    />
                                )}
                            </Usp>
                        ))}
                    </Usps>
                )}
                <Link href={link?.url} passHref prefetch={false}>
                    <StyledButton element="a" variant="primary">
                        {link?.label}
                    </StyledButton>
                </Link>
                {!mobileView && <ImagesDesktop items={images} />}
            </Container>
        </Wrapper>
    );
}

Business.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    )
};

Business.defaultProps = {
    title: "Het perfecte zakelijke geschenk",
    description:
        "De Nationale Diner Cadeaukaart is een perfect geschenk voor je personeel of zakelijke relaties. Als zakelijke klant geniet je altijd van persoonlijke service, kun je gebruik maken van leuke extra opties en betaal je gemakkelijk via factuur.",
    images: undefined,
    link: {
        url: "/restaurantbon-bestellen/",
        label: "bestel je nationale diner cadeau"
    },
    blocks: [
        {
            title: "Bestel op factuur",
            description:
                "Voor elke soort bestelling kan je makkelijk bestellen via factuur. Kies in het bestelformulier voor betaalmethode ‘Op factuur’.",
            link: {
                url: "/restaurantbon-bestellen/",
                label: "Direct naar bestellen"
            }
        },
        {
            title: "Goede persoonlijke service",
            description:
                "Op het gebied van zakelijke bestellingen is er vaak veel mogelijk, heb je bijvoorbeeld een goed idee over een verpakking? Let us know! ",
            link: {
                url: "/zakelijk/",
                label: "Bekijk de mogelijkheden"
            }
        },
        {
            title: "Cadeauservice",
            description:
                "Geef je vaker een cadeaukaart aan je zakelijke relaties en/of personeel? Maak dan kennis met Cadeauservice!",
            link: {
                url: "/zakelijk/",
                label: "Neem contact op"
            }
        },
        {
            title: "Personalisatie",
            description:
                "Kies voor een speciale verpakking, laat je bedrijfslogo op de kaart drukken en/of laat een boodschap achter. De mogelijkheden zijn eindeloos.",
            link: {
                url: "/zakelijk/",
                label: "Bekijk de mogelijkheden"
            }
        }
    ]
};

export default Business;
