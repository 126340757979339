import styled from "styled-components";
import media from "constants/media";

import Button from "components/ui/button/Button";
import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Wrapper = styled.div`
    padding: 45px 0 20px;

    ${media.mdUp} {
        padding: 0 0 85px;
    }
`;

export const Container = styled(BaseContainer)`
    position: relative;

    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const Intro = styled.div`
    z-index: 2;

    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 0 30px;
    padding: 0;

    ${media.mdUp} {
        min-width: 45%;
        margin: 0;
        padding: 0 80px 0 0;
    }

    ${media.xlUp} {
        min-width: 500px;
        padding: 0 120px 0 0;
    }
`;

export const Title = styled.h2`
    margin: 0 0 15px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);

    ${media.lgUp} {
        margin: 0 0 20px;
    }
`;

export const Description = styled(ContentRenderer)`
    margin: 0 0 15px;

    ${media.mdUp} {
        margin: 0 0 20px;
    }
`;

export const StyledButton = styled(Button)`
    margin: 0 auto;

    ${media.mdUp} {
        margin: 0 auto 0 0;
    }
`;
