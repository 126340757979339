import PropTypes from "prop-types";

import landingPagePropTypes from "types/LandingPage.propTypes";
import { filterMerchants } from "helpers/merchant";
import { resolveApiData, resolveUsps } from "helpers/page";

import SeoText from "components/shared/seoText/SeoText";
import UspBar from "components/shared/uspBar/UspBar";

import Hero from "./hero/Hero";
import Steps from "./steps/Steps";
import Usps from "./usps/Usps";
import Usage from "./usage/Usage";
import Business from "./business/Business";
import Merchants from "./merchants/Merchants";
import Doubler from "./doubler/Doubler";

function resolveBlockData(block) {
    return block && block.length > 0 ? block[0] : undefined;
}

function LandingPage({ data, isChristmas }) {
    const {
        hero,
        seo,
        steps,
        usps,
        received,
        sections,
        blocks,
        popularMerchants
    } = data || {};
    const { first, second, third } = seo || {};

    const stepsContent = resolveBlockData(steps);
    const usageContent = resolveBlockData(received);
    const uspsContent = blocks?.[0] ?? undefined;
    const businessContent = blocks?.[1] ?? undefined;
    const merchantsContent = blocks?.[2] ?? undefined;
    const doublerContent = blocks?.[3] ?? undefined;
    const merchants = filterMerchants(popularMerchants);

    return (
        <>
            <Hero
                title={hero?.title}
                backgroundImages={hero?.background}
                cardImage={hero?.image}
                isChristmas={isChristmas}
            />
            {first &&
                resolveApiData(first, "title") &&
                resolveApiData(first, "description") && (
                    <SeoText
                        title={first?.title}
                        text={first?.description}
                        clamp
                    />
                )}
            <Steps title={stepsContent?.title} items={stepsContent?.sections} />
            <Usps items={usps} />
            {sections.usage && (
                <Usage
                    title={usageContent?.title}
                    sections={usageContent?.sections}
                    backgroundImages={usageContent?.images}
                    isChristmas={isChristmas}
                />
            )}
            <UspBar items={resolveUsps(uspsContent?.sections)} />
            {sections.business && (
                <Business
                    title={businessContent?.title}
                    description={businessContent?.description}
                    images={businessContent?.images}
                    link={resolveApiData(businessContent, "link")}
                    blocks={businessContent?.sections}
                />
            )}
            {sections.restaurants && (
                <Merchants
                    title={merchantsContent?.title}
                    description={merchantsContent?.description}
                    merchantsTitle={merchantsContent?.sections?.[0]?.title}
                    merchants={merchants?.edges}
                />
            )}
            {sections.doubler && (
                <Doubler seo={second} doubler={doublerContent} />
            )}
            {third &&
                resolveApiData(third, "title") &&
                resolveApiData(third, "description") && (
                    <SeoText
                        title={third?.title}
                        text={third?.description}
                        position="bottom"
                    />
                )}
        </>
    );
}

LandingPage.propTypes = {
    data: landingPagePropTypes,
    isChristmas: PropTypes.bool
};

LandingPage.defaultProps = {
    data: {
        hero: {
            title: "De leukste ervaring om te geven."
        },
        seo: {},
        steps: [],
        received: [],
        popularMerchants: {
            edges: []
        },
        blocks: [],
        sections: {
            restaurants: true,
            business: true,
            themes: true,
            usage: true,
            doubler: true
        }
    },
    isChristmas: process.env.featureFlags.christmas
};

export default LandingPage;
