export function resolveApiData(data, property) {
    if (data && data[property] && data[property] !== null) {
        return data[property];
    }

    return undefined;
}

export function resolveUsps(sections) {
    if (Array.isArray(sections) && sections.length > 0) {
        return sections.map(section => section.title);
    }

    return undefined;
}

export function resolveImages(images, mobile, desktop) {
    if (images && Array.isArray(images)) {
        if (images.length === 1) {
            return { mobile: images[0], desktop: images[0] };
        }

        if (images.length > 1) {
            return { mobile: images[0], desktop: images[1] };
        }
    }

    return {
        mobile,
        desktop
    };
}
