import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    position: relative;

    margin: 30px 0 50px;

    ${media.mdUp} {
        margin: 55px 0 0;
    }
`;
