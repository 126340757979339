import { useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import Button from "components/ui/button/Button";

import { Title, Text, Controls } from "./ClampedText.styled";

function ClampedText({ title, text, linesVisible }) {
    const [visibleLines, setVisibleLines] = useState(linesVisible);
    const [isShowingMore, setIsShowingMore] = useState(false);

    function showMoreText() {
        setIsShowingMore(!isShowingMore);

        if (isShowingMore) {
            setVisibleLines(linesVisible);
        } else {
            setVisibleLines(100);
        }
    }

    return (
        <div>
            {title && <Title>{title}</Title>}
            <Text lines={visibleLines} isShowingMore={isShowingMore}>
                {parse(text)}
            </Text>
            <Controls>
                <Button variant="ghost" onClick={showMoreText}>
                    {isShowingMore ? "Minder lezen" : "Verder lezen"}
                </Button>
            </Controls>
        </div>
    );
}

ClampedText.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    linesVisible: PropTypes.number
};

ClampedText.defaultProps = {
    title: "",
    text: "",
    linesVisible: 4
};

export default ClampedText;
