import styled from "styled-components";

import media from "constants/media";

import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled.div`
    width: 100vw;
    margin: 0 -10px;
    padding: 30px 10px 20px;

    background-color: var(--color-purple-90);

    ${media.mdUp} {
        flex: 1 1 auto;
        width: 50%;
        margin: 0;
        padding: 40px 50px;

        border-radius: var(--border-radius-default);
    }
`;

export const Title = styled.h2`
    margin: 0 0 15px;

    font-weight: bold;
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);

    ${media.mdUp} {
        font-size: var(--font-size-h3);
        line-height: var(--line-height-h3);
    }
`;

export const Text = styled(ContentRenderer)`
    margin: 0;
`;
