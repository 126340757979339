import styled from "styled-components";

export const Container = styled.div`
    position: relative;

    width: 100vw;
    max-width: 370px;
    height: 200px;

    margin: 0 auto;
`;
