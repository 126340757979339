import PropTypes from "prop-types";
import Image from "next/image";
import { useTrail, config } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

import { Container, ImageContainer } from "./Images.styled";

function Images({ items }) {
    const { ref, inView } = useInView({
        threshold: 0.25,
        triggerOnce: true
    });
    const trail = useTrail(items.length, {
        config: config.stiff,
        opacity: inView ? 1 : 0,
        scale: inView ? 1 : 0,
        from: {
            opacity: 0,
            scale: 0
        }
    });

    return (
        <Container ref={ref}>
            {trail.map(({ ...style }, index) => {
                const { type, src, alt, width, height, top, left } =
                    items[index];

                return (
                    <ImageContainer
                        key={`business-img-${index}-${alt}`}
                        type={type}
                        width={width}
                        height={height}
                        top={top}
                        left={left}
                        style={style}
                    >
                        <Image
                            src={src}
                            alt={alt}
                            width={width}
                            height={height}
                            quality={75}
                        />
                    </ImageContainer>
                );
            })}
        </Container>
    );
}

Images.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["card", "logo"]).isRequired,
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
            top: PropTypes.number.isRequired,
            left: PropTypes.number.isRequired
        }).isRequired
    ).isRequired
};

export default Images;
