import styled from "styled-components";

export const Score = styled.span`
    display: inline-flex;
    gap: 0 5px;
    justify-items: center;

    color: var(--color-green);
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
`;
