import styled from "styled-components";

import media, { sizes } from "constants/media";

import IconCheckmark from "components/ui/icons/IconCheckmark";

export const UspList = styled.ul`
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 20px 0;
    margin: 50px 10px 60px;
    padding: 25px 15px;

    list-style: none;
    background-color: var(--color-white);
    border-radius: 3px;
    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05);

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 10px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: calc(100vw - 20px);
        margin: 50px auto -35px;
        padding: 20px;

        box-shadow: var(--box-shadow-standard);
    }

    ${media.lgUp} {
        padding: 20px 40px;
    }

    ${media.xlUp} {
        max-width: ${sizes.xl}px;
    }
`;

export const UspItem = styled.li`
    display: flex;

    font-size: var(--font-size-body);
    line-height: 23px;

    ${media.mdUp} {
        align-items: flex-start;

        line-height: 30px;

        svg {
            width: 24px;
            height: 24px;
            margin: 3px 10px 3px 0;
        }
    }
`;

export const Checkmark = styled(IconCheckmark)`
    flex: 0 0 auto;
    margin: 0 15px 0 0;

    ${media.mdUp} {
        width: 24px;
        height: 24px;
        margin: 3px 10px 3px 0;
    }
`;
