import styled from "styled-components";

import media from "constants/media";

export const MerchantSliderStyled = styled.div`
    width: 100%;
`;

export const Title = styled.h3`
    margin: 0 0 30px;

    font-weight: bold;
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);

    ${media.mdUp} {
        margin: 0 0 40px;
    }
`;
