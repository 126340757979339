import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";

import Container from "components/ui/container/Container";

import {
    StepsStyled,
    MobileSlider,
    Heading,
    ItemMobile,
    Items,
    Item,
    Title,
    Text
} from "./Steps.styled";

const Slider = dynamic(() => import("components/ui/slider/Slider"));

export default function Steps({ title, items }) {
    const { inView, ref } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });

    return (
        <StepsStyled>
            <Container>
                <Heading>{title}</Heading>
                <MobileSlider ref={ref}>
                    {inView && (
                        <Slider
                            overflowVisible
                            navigationDesktop={false}
                            navigationMobile={false}
                            items={items?.map((item, index) => (
                                <ItemMobile
                                    key={`steps-m-${index}-${item.title}`}
                                >
                                    {item.title && <Title>{item.title}</Title>}
                                    {item.description && (
                                        <Text>{item.description}</Text>
                                    )}
                                </ItemMobile>
                            ))}
                        />
                    )}
                </MobileSlider>
                <Items>
                    {items?.map((item, index) => (
                        <Item key={`steps-d-${index}-${item.title}`}>
                            {item.title && <Title>{item.title}</Title>}
                            {item.description && (
                                <Text>{item.description}</Text>
                            )}
                        </Item>
                    ))}
                </Items>
            </Container>
        </StepsStyled>
    );
}

Steps.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        })
    )
};

Steps.defaultProps = {
    title: "Hoe werkt het?",
    items: [
        {
            title: "Bepaal een waarde",
            description:
                "Jij kiest het saldo op de kaart, dit saldo mag in één keer of in delen worden verzilverd. Je kan ook meerdere kaarten met verschillende waardes bestellen."
        },
        {
            title: "Persoonlijk maken",
            description:
                "Personaliseer jouw cadeau met je eigen boodschap, verpakking of fles wijn. Reken af met een betaalmethode naar keuze."
        },
        {
            title: "Je ontvanger geniet",
            description:
                "Hij/zij mag kiezen uit 3000 restaurants, geniet van een etentje en betaald met de kaart."
        }
    ]
};
