import styled from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    margin: 25px 0;
    padding: 0 10px;

    ${media.mdUp} {
        margin: 40px auto 70px;
    }

    ${media.lgUp} {
        padding: 0;
    }
`;

export const LinkItems = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;

    ${media.mdUp} {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 -10px;
    }
`;

export const LinkItem = styled.li`
    width: 100%;
    margin-bottom: 40px;
    padding: 0;

    ${media.mdUp} {
        flex: 1 0 50%;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    ${media.lgUp} {
        flex: 1 0 25%;
        padding: 0 10px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 5px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
`;
