import PropTypes from "prop-types";
import parse from "html-react-parser";

import { UspItem, UspList, Checkmark } from "./UspBar.styled";

function UspBar({ items, className }) {
    return (
        <UspList className={className}>
            {items?.map(item => (
                <UspItem key={JSON.stringify(item)}>
                    <Checkmark size={26} fill="var(--color-green)" />
                    <span>{parse(item)}</span>
                </UspItem>
            ))}
        </UspList>
    );
}

UspBar.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string
};

UspBar.defaultProps = {
    items: [
        "Voor 17.00 uur besteld = <strong>vandaag verstuurd</strong>",
        "<strong>Gratis</strong> verpakt in mooie cadeau-envelop",
        "<strong>96%</strong> beveelt ons aan <strong>8,8</strong>/10"
    ],
    className: ""
};
export default UspBar;
