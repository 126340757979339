import styled from "styled-components";

import media from "constants/media";
import colors from "constants/colors";
import { hexToRgba } from "helpers/utils";

const borderColor = hexToRgba(colors.yellow, 0.3);

export const Wrapper = styled.div`
    padding: 45px 10px;

    /* prettier-ignore */
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
        url(${({ mobileImage }) => mobileImage}) top center no-repeat;
    background-size: cover;

    ${media.mdUp} {
        padding: 70px 15px;

        /* prettier-ignore */
        background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), url(${({
            desktopImage
        }) => desktopImage});
    }

    ${media.lgUp} {
        padding: 70px calc((100vw - var(--container-width)) / 2);
    } ;
`;

export const Container = styled.div`
    color: var(--color-white);

    ${media.mdUp} {
        max-width: 380px;
    }
`;

export const Title = styled.h2`
    margin: 0;

    font-weight: bold;
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);

    ${media.mobileHide}
`;

export const Subtitle = styled.h3`
    margin: 0 0 15px;
    padding: 0;

    font-weight: bold;
    font-size: var(--font-size-${({ as }) => (as === "h3" ? "h5" : "h6")});
    line-height: 30px;
`;

export const Text = styled.p`
    margin: 0 0 20px;
`;

export const List = styled.ul`
    margin: 0 0 20px;
    padding: 0;

    list-style: none;

    &:last-of-type {
        margin: 0;
    }
`;

export const ListItem = styled.li`
    position: relative;

    display: flex;
    width: 100%;

    border-top: 1px solid ${borderColor};

    :last-child {
        border-bottom: 1px solid ${borderColor};
    }
`;

export const Anchor = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 8px 0;

    color: var(--color-secondary);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    svg {
        transition: all var(--animation-duration-default) ease;
    }

    :hover,
    :focus {
        color: var(--color-white);

        svg {
            transform: translateX(5px);

            fill: var(--color-white);
        }
    }
`;

export const LinkImage = styled.img`
    margin-right: 10px;
`;

export const LinkText = styled.span`
    flex: 0 1 100%;
`;
