import { FormProvider, useForm } from "react-hook-form";
import { useRouter } from "next/router";

import { generateQueryString } from "helpers/utils";

import QuickSearch from "components/shared/quickSearch/QuickSearch";

import { Container } from "./Finder.styled";

function Finder() {
    const router = useRouter();
    const methods = useForm({
        mode: "onSubmit"
    });

    const onSubmit = ({ q }) => {
        const queryString = generateQueryString({ q });
        router.push(`/restaurants/?${queryString}`);
    };

    return (
        <Container>
            <FormProvider {...methods}>
                <form
                    method="GET"
                    action="/restaurants/"
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <QuickSearch
                        name="q"
                        label="Restaurants in de buurts"
                        placeholder="Postcode, plaats of restaurantnaam"
                        geoLocationPosition="inline"
                    />
                </form>
            </FormProvider>
        </Container>
    );
}

export default Finder;
