import PropTypes from "prop-types";

import Images from "../Images";

import { Container } from "./ImagesDesktop.styled";

const defaultItems = [
    {
        type: "card",
        url: "/images/cards/img-card-palmpapier.png",
        alt: "Voorbeeld cadeaukaart met het Palmpapier Logo",
        width: 405,
        height: 262,
        top: 40,
        left: 60
    },
    {
        type: "card",
        url: "/images/cards/img-card-belisol.png",
        alt: "Voorbeeld cadeaukaart met het Belisol logo",
        width: 176,
        height: 114,
        top: -40,
        left: 530
    },
    {
        type: "logo",
        url: "/images/payment-methods/logo-ing.svg",
        alt: "ING logo",
        width: 122,
        height: 122,
        top: 90,
        left: 555
    },
    {
        type: "logo",
        url: "/images/payment-methods/logo-rabobank-xl.svg",
        alt: "Rabobank logo",
        width: 98,
        height: 98,
        top: 400,
        left: 65
    },
    {
        type: "card",
        url: "/images/cards/img-card-sako.png",
        alt: "Voorbeeld cadeaukaart met het Sako Juwelier logo",
        width: 284,
        height: 184,
        top: 355,
        left: 215
    },
    {
        type: "card",
        url: "/images/cards/img-card-vd-akker.png",
        alt: "Voorbeeld cadeaukaart met het VD Akker Logo",
        width: 270,
        height: 174,
        top: 235,
        left: 550
    },
    {
        type: "logo",
        url: "/images/logos/logo-postnl.svg",
        alt: "PostNL logo",
        width: 46,
        height: 46,
        top: 495,
        left: 600
    },
    {
        type: "card",
        url: "/images/cards/img-card-reclame-studio.png",
        alt: "Voorbeeld cadeaukaart met het Reclame Studio logo",
        width: 151,
        height: 98,
        top: 620,
        left: 135
    },
    {
        type: "card",
        url: "/images/cards/img-card-kws.png",
        alt: "Voorbeeld cadeaukaart met het KWS logo",
        width: 266,
        height: 172,
        top: 615,
        left: 395
    },
    {
        type: "logo",
        url: "/images/payment-methods/logo-abnamro.svg",
        alt: "ABN AMRO logo",
        width: 132,
        height: 132,
        top: 745,
        left: 190
    }
];

function ImagesDesktop({ items }) {
    const images = defaultItems.map((item, index) => {
        if (items[index]) {
            const { url, alt } = items[index];

            return { ...item, src: url, alt };
        }

        return item;
    });

    return (
        <Container>
            <Images items={images} />
        </Container>
    );
}

ImagesDesktop.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    )
};

ImagesDesktop.defaultProps = {
    items: defaultItems
};

export default ImagesDesktop;
