import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    position: absolute;
    top: 0;
    right: -35%;

    width: 35%;
    height: 100%;
    padding: 0;

    ${media.lgUp} {
        right: -65%;

        width: 65%;
    }

    ${media.xlUp} {
        right: -100%;

        width: 100%;
    }
`;
