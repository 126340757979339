import { Image, Title, Theme, Location } from "./SkeletonCard.styled";

import { Container, ImageContainer, Content } from "../Card.styled";

function SkeletonCard() {
    return (
        <Container>
            <ImageContainer>
                <Image />
            </ImageContainer>
            <Content>
                <Title />
                <Theme />
                <Location />
            </Content>
        </Container>
    );
}

export default SkeletonCard;
