import PropTypes from "prop-types";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import ClampedText from "components/ui/clampedText/ClampedText";

import { Container, Title, Text } from "./UseCard.styled";

function UseCard({ title, description }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Container>
            {mobileView ? (
                <ClampedText
                    linesVisible={5}
                    title={title}
                    text={description}
                />
            ) : (
                <>
                    <Title>{title}</Title>
                    <Text>{description}</Text>
                </>
            )}
        </Container>
    );
}

UseCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

UseCard.defaultProps = {
    title: "Je nationale diner cadeaukaart besteden",
    description:
        "De Nationale Diner Cadeaukaart kan je besteden bij ruim 3000 restaurants in Nederland. Er zit altijd wel een aangesloten restaurant in je omgeving, maar natuurlijk kan je er ook op uit met de dinercheque van Nationale Diner Cadeau! Ooit al wel eens gegeten in een kasteel? Nationale Diner Cadeau is bij verschillende prachtige kastelen te besteden. Hou je meer van zon, zee en strand? Bij de aangesloten strandtenten geniet je van een lekker zomers diner. Ben je een echte fijnproever? Nationale Diner Cadeau kan ook besteed worden bij een selectie aan sterrenrestaurants waar je je smaakpapillen kan laten prikkelen! Nationale Diner Cadeau is een veelzijdig culinaire dinercheque met voor iedere smaak wat wils."
};

export default UseCard;
