import PropTypes from "prop-types";
import Link from "next/link";
import parse from "html-react-parser";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import {
    Wrapper,
    Container,
    Title,
    Subtitle,
    List,
    ListItem,
    Anchor,
    LinkImage,
    LinkText,
    Text
} from "./Usage.styled";

function resolveBackground(images, isChristmas) {
    if (isChristmas) {
        return {
            mobileImage: "/images/banners/usage-banner-kerst-mobile.jpg",
            desktopImage: "/images/banners/usage-banner-kerst-desktop.jpg"
        };
    }

    if (images.length === 1) {
        return {
            mobileImage: images[0].url,
            desktopImage: images[0].url
        };
    }

    if (images.length > 1) {
        return {
            mobileImage: images[0].url,
            desktopImage: images[1].url
        };
    }

    return {
        mobileImage: "/images/banners/usage-banner-mobile.jpg",
        desktopImage: "/images/banners/usage-banner-desktop.jpg"
    };
}

const renderLinks = items => {
    return items.map((item, index) => {
        const { link, images } = item;

        if (!item?.link) {
            return null;
        }

        return (
            <ListItem key={`${index}}-${link.label}`}>
                <Link href={link.url} passHref prefetch={false}>
                    <Anchor>
                        {images?.[0]?.url && (
                            <LinkImage
                                src={images[0].url}
                                alt={images[0].alt ?? ""}
                                width={26}
                                height={26}
                                loading="lazy"
                            />
                        )}
                        <LinkText>{link.label}</LinkText>
                        <IconChevronRight
                            size={32}
                            fill="var(--color-yellow)"
                        />
                    </Anchor>
                </Link>
            </ListItem>
        );
    });
};

function Usage({ title, sections, backgroundImages, isChristmas }) {
    const { mobileImage, desktopImage } = resolveBackground(
        backgroundImages,
        isChristmas
    );

    return (
        <Wrapper mobileImage={mobileImage} desktopImage={desktopImage}>
            <Container>
                <Title>{title}</Title>
                {sections.map((section, index) => (
                    <>
                        <Subtitle as={index === 0 ? "h3" : "h4"}>
                            {section.title}
                        </Subtitle>
                        {section.description && (
                            <Text>{parse(section.description)}</Text>
                        )}
                        {section.sections && (
                            <List>{renderLinks(section.sections)}</List>
                        )}
                    </>
                ))}
            </Container>
        </Wrapper>
    );
}

Usage.propTypes = {
    title: PropTypes.string,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    images: PropTypes.arrayOf(
                        PropTypes.shape({
                            alt: PropTypes.string,
                            url: PropTypes.string
                        })
                    ),
                    link: PropTypes.shape({
                        label: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            )
        })
    ),
    backgroundImages: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string
        })
    ),
    isChristmas: PropTypes.bool
};

Usage.defaultProps = {
    title: "Ontvangen?",
    sections: [
        {
            title: "Heb je een Nationale Diner Cadeaukaart ontvangen?",
            description:
                "Gefeliciteerd! Je kunt heerlijk uit eten bij ruim 3000 restaurants door heel Nederland.",
            sections: [
                {
                    link: {
                        label: "Vind een restaurant",
                        url: "/restaurants/"
                    }
                },
                {
                    link: {
                        label: "Check je saldo",
                        url: "/saldocheck/"
                    }
                }
            ]
        },
        {
            title: "Maak nu kans op verdubbeling van je saldo!",
            sections: [
                {
                    images: [
                        {
                            url: "/images/icon-doubler-yellow.svg",
                            alt: "Verdubbele je saldo"
                        }
                    ],
                    link: {
                        label: "Verdubbel je saldo",
                        url: "/verdubbelaar/"
                    }
                }
            ]
        }
    ],
    backgroundImages: [
        { url: "/images/banners/usage-banner-mobile.jpg" },
        { url: "/images/banners/usage-banner-desktop.jpg" }
    ],
    isChristmas: false
};

export default Usage;
