import styled from "styled-components";

export const Anchor = styled.a`
    display: block;

    color: var(--color-primary);
    font-weight: bold;
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    svg {
        transition: all var(--animation-duration-default) ease;
    }

    :hover,
    :focus {
        color: var(--color-purple-20);

        svg {
            transform: translateX(5px);

            fill: var(--color-purple-20);
        }
    }
`;
