import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { useInView } from "react-intersection-observer";

import { MerchantSliderItemApiPropTypes } from "types/MerchantSliderItem.propTypes";
import { filterMerchants } from "helpers/merchant";
import { GetMerchantsSlides } from "lib/api/cc/queries/merchants.gql";

import Slider from "components/ui/slider/Slider";

import SkeletonCard from "./card/skeleton/SkeletonCard";
import Card from "./card/Card";

import { MerchantSliderStyled, Title } from "./MerchantSlider.styled";

function resolveThemes(themes) {
    if (!themes) {
        return [];
    }

    return themes?.edges.map(theme => theme.title);
}

function renderCards(merchants) {
    if (!merchants || merchants.length <= 0) {
        return [];
    }

    return merchants.map(
        ({ title, slug, images, score, themes, address, id }) => (
            <Card
                key={id}
                slug={slug}
                title={title}
                score={score}
                image={
                    images?.[0] ?? {
                        url: "/images/placeholder.jpg",
                        alt: title
                    }
                }
                address={address}
                themes={resolveThemes(themes)}
            />
        )
    );
}

const generateSkeletonCards = limit =>
    Array(limit)
        .fill()
        .map((item, index) => (
            <SkeletonCard key={`MerchantSlider-skeleton-${index}`} />
        ));

function MerchantSlider({ title, merchants, filter, limit, slidesToShow }) {
    const [cards, setCards] = useState(generateSkeletonCards(limit));
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });
    const { loading } = useQuery(GetMerchantsSlides, {
        variables: {
            filter,
            pageSize: limit,
            sort: "PROMOTED"
        },
        onCompleted: ({ merchants }) => {
            const filteredMerchants = filterMerchants(merchants, true);

            if (filteredMerchants?.edges?.length > 0) {
                setCards(renderCards(filteredMerchants?.edges));
            }
        },
        skip:
            merchants?.length > 0 ||
            !inView ||
            !process.env.featureFlags.cmsData
    });

    useEffect(() => {
        if (merchants.length > 0) {
            setCards(renderCards(merchants));
        }
    }, [merchants]);

    return (
        <MerchantSliderStyled ref={ref} aria-busy={loading}>
            <Title>{title}</Title>
            {inView && (
                <Slider
                    navigationMobile={false}
                    items={cards}
                    slidesToShow={slidesToShow}
                    overflowVisible
                    fadeEdges
                />
            )}
        </MerchantSliderStyled>
    );
}

MerchantSlider.propTypes = {
    title: PropTypes.string,
    merchants: PropTypes.arrayOf(MerchantSliderItemApiPropTypes),
    filter: PropTypes.object,
    limit: PropTypes.number,
    slidesToShow: PropTypes.number
};

MerchantSlider.defaultProps = {
    title: "Meest populaire restaurants",
    merchants: [],
    filter: {
        promoted: true
    },
    limit: 6,
    slidesToShow: 2.7
};

export default MerchantSlider;
