import PropTypes from "prop-types";

function IconStar({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M32,52.9l14.3,8.6c3.5,2.1,7.7-1,6.8-5l-3.8-16.2l12.6-10.9c3.1-2.6,1.4-7.7-2.6-8L42.7,20L36.2,4.6c-1.6-3.7-6.8-3.7-8.4,0L21.3,20L4.7,21.4c-4,0.3-5.7,5.4-2.6,8l12.6,10.9l-3.8,16.2c-0.9,3.9,3.4,7,6.8,5L32,52.9z" />
        </svg>
    );
}

IconStar.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconStar.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconStar;
