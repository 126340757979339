import styled from "styled-components";

import media from "constants/media";

import Button from "components/ui/button/Button";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Wrapper = styled.div`
    padding: 20px 10px 30px;
    overflow: hidden;

    background-color: var(--color-purple-90);

    ${media.mdUp} {
        min-height: 1025px;
        margin-bottom: 85px;
        padding: 130px 0 135px;
    }
`;

export const Container = styled.div`
    position: relative;

    width: 100%;

    ${media.mdUp} {
        max-width: 65%;
        padding: 0 0 0 10px;
    }

    ${media.lgUp} {
        max-width: calc(var(--container-width) * 0.6);
        margin: 0 0 0 calc((100vw - var(--container-width)) * 0.6);
        padding: 0;
    }

    ${media.xlUp} {
        max-width: calc(var(--container-width) * 0.5);
        margin: 0 0 0 calc((100vw - var(--container-width)) * 0.5);
    }
`;

export const Title = styled.h2`
    margin: 0 0 15px;

    font-weight: bold;
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);
`;
export const Text = styled(ContentRenderer)`
    margin: 0 0 10px;

    ${media.mdUp} {
        margin: 0 0 20px;
    }
`;
export const Usps = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        flex-direction: row;
        flex-wrap: wrap;

        margin: 65px 0 0;
    }
`;
export const Usp = styled.div`
    margin-bottom: 25px;

    ${media.smUp} {
        flex: 0 0 50%;
        margin-bottom: 50px;

        :nth-child(odd) {
            padding-right: 20px;
        }
    }
`;

export const Subtitle = styled.h3`
    margin: 0 0 10px;

    font-weight: bold;
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
`;

export const StyledButton = styled(Button)`
    display: block;
    margin: 10px 5px 0;

    text-align: center;

    ${media.mdUp} {
        display: inline-flex;
        margin: 0;
    }
`;
