import PropTypes from "prop-types";
import Link from "next/link";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import { Anchor } from "./ArrowLink.styled";

function ArrowLink({ url, label }) {
    return (
        <Link href={url} passHref prefetch={false}>
            <Anchor>
                <span>{label}</span>
                <IconChevronRight
                    fill="var(--color-purple)"
                    size={22}
                    aria-hidden="true"
                />
            </Anchor>
        </Link>
    );
}

ArrowLink.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default ArrowLink;
