import PropTypes from "prop-types";

import ArrowLink from "components/ui/arrowLink/ArrowLink";

import { Container, LinkItems, LinkItem, Title } from "./Usps.styled";

function Usps({ items }) {
    return (
        <Container>
            <LinkItems>
                {items.map((item, index) => (
                    <LinkItem key={`usps-${index}-${item.title}`}>
                        <Title>{item.title}</Title>
                        {item.link?.url && (
                            <ArrowLink
                                url={item.link.url}
                                label={item.link.label}
                            />
                        )}
                    </LinkItem>
                ))}
            </LinkItems>
        </Container>
    );
}

Usps.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    )
};

Usps.defaultProps = {
    items: [
        {
            title: "Eindeloos personaliseren",
            link: {
                url: "/restaurantbon-bestellen/",
                label: "Maak jouw cadeau"
            }
        },
        {
            title: "3000 restaurants",
            link: {
                url: "/restaurants/",
                label: "Bekijk alle restaurants"
            }
        },
        {
            title: "Gratis verpakt in een mooie cadeau-envelop",
            link: {
                url: "/restaurantbon-bestellen/",
                label: " Of kies een speciale verpakking"
            }
        },
        {
            title: "Waarde van € 5,- tot € 150,-",
            link: {
                url: "/saldocheck/",
                label: "Check jouw saldo"
            }
        }
    ]
};

export default Usps;
