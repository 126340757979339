import PropTypes from "prop-types";
import Images from "../Images";

import { Container } from "./ImagesMobile.styled";

const defaultItems = [
    {
        type: "card",
        url: "/images/cards/img-card-palmpapier.png",
        alt: "Voorbeeld cadeaukaart met het Palmpapier Logo",
        width: 109,
        height: 70,
        top: 30,
        left: 0
    },
    {
        type: "card",
        url: "/images/cards/img-card-belisol.png",
        alt: "Voorbeeld cadeaukaart met het Belisol logo",
        width: 48,
        height: 30,
        top: 20,
        left: 125
    },
    {
        type: "card",
        url: "/images/cards/img-card-sako.png",
        alt: "Voorbeeld cadeaukaart met het Sako Juwelier logo",
        width: 77,
        height: 50,
        top: 0,
        left: 190
    },
    {
        type: "card",
        url: "/images/cards/img-card-reclame-studio.png",
        alt: "Voorbeeld cadeaukaart met het Reclame Studio logo",
        width: 54,
        height: 35,
        top: 110,
        left: 60
    },
    {
        type: "card",
        url: "/images/cards/img-card-vd-akker.png",
        alt: "Voorbeeld cadeaukaart met het VD Akker Logo",
        width: 73,
        height: 48,
        top: 65,
        left: 120
    },
    {
        type: "card",
        url: "/images/cards/img-card-kws.png",
        alt: "Voorbeeld cadeaukaart met het KWS logo",
        width: 136,
        height: 88,
        top: 70,
        left: 215
    }
];

function ImagesMobile({ items }) {
    const images = defaultItems.map((item, index) => {
        if (items[index]) {
            const { url, alt } = items[index];
            return { ...item, src: url, alt };
        }

        return item;
    });

    return (
        <Container>
            <Images items={images} />
        </Container>
    );
}

ImagesMobile.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    )
};

ImagesMobile.defaultProps = {
    items: defaultItems
};

export default ImagesMobile;
