import Image from "next/image";
import Link from "next/link";
import slugify from "slugify";

import MerchantSliderItemPropTypes from "types/MerchantSliderItem.propTypes";

import Rating from "components/ui/rating/Rating";

import {
    Container,
    Content,
    ImageContainer,
    Title,
    Theme,
    Location,
    ArrowIcon
} from "./Card.styled";

function Card({ slug, title, image, score, themes, address }) {
    const province = slugify(address?.provinceCode ?? "", {
        lower: true
    });
    const city = slugify(address?.city ?? "", {
        lower: true
    });
    const href = `/restaurants/${province}/${city}/${slug}/`;

    return (
        <Container>
            <Link href={href} passHref prefetch={false}>
                <a>
                    <ImageContainer>
                        {image && (
                            <Image
                                src={image.url}
                                alt={image.alt}
                                quality={75}
                                layout="fill"
                                objectFit="cover"
                            />
                        )}
                    </ImageContainer>
                    <Content>
                        <Title aria-label={`Restaurant: ${title}`}>
                            {title}
                        </Title>
                        <Rating score={score} />
                        {themes && (
                            <Theme aria-label={`Thema's: ${themes.join(",")}`}>
                                {themes.join(", ")}
                            </Theme>
                        )}
                        {address?.city && (
                            <Location aria-label={`Locatie: ${address.city}`}>
                                {address.city}
                            </Location>
                        )}
                        <ArrowIcon
                            size={26}
                            fill="var(--color-primary)"
                            aria-hidden="true"
                        />
                    </Content>
                </a>
            </Link>
        </Container>
    );
}

Card.propTypes = MerchantSliderItemPropTypes;

Card.defaultProps = {
    score: 0,
    themes: []
};

export default Card;
