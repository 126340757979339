import styled from "styled-components";
import media from "constants/media";

import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const StepsStyled = styled.div`
    width: 100%;
    margin: 0;

    ${media.mdUp} {
        margin: 95px auto 60px;
    }
`;

export const MobileSlider = styled.div`
    ${media.mobileOnly}

    .slider-slide {
        background: var(--color-white);
        border-radius: var(--border-radius-default);
        box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05);
    }
`;

export const Heading = styled.h2`
    margin: 40px 0 30px;

    font-size: var(--font-size-h2);
    line-height: var(--line-height-body);

    ${media.mdUp} {
        margin: 0 0 40px;

        line-height: var(--line-height-h2);
        text-align: center;
    }
`;

export const ItemMobile = styled.div`
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    padding: 30px 20px;
`;

export const Items = styled.ul`
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
    padding: 0;

    list-style: none;

    ${media.mobileHide}
`;

export const Item = styled.li`
    position: relative;
    z-index: 3;

    flex: 1 1 100%;
    max-width: 480px;
    margin: 0 10px;
    padding: 30px 25px;

    background: var(--color-white);
    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05);
`;

export const Title = styled.h3`
    margin: 0 0 10px;

    font-weight: bold;
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
    text-align: center;
`;

export const Text = styled(ContentRenderer)`
    margin: 0;

    text-align: center;
`;
