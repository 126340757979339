import PropTypes from "prop-types";

import { resolveApiData } from "helpers/page";

import DoubleCard from "components/shared/doubleCard/DoubleCard";

import UseCard from "./useCard/UseCard";

import { Column, Container } from "./Doubler.styled";

function Doubler({ seo, doubler }) {
    return (
        <Container>
            <UseCard
                title={seo?.title ?? undefined}
                description={seo?.description ?? undefined}
            />
            <Column>
                <DoubleCard
                    images={doubler?.images}
                    title={doubler?.title}
                    description={doubler?.description}
                    link={resolveApiData(doubler, "link")}
                />
            </Column>
        </Container>
    );
}

Doubler.propTypes = {
    seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
    }),
    doubler: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string
        }),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        )
    })
};

Doubler.defaultProps = {
    seo: {
        title: "Je nationale diner cadeaukaart besteden",
        description:
            "Nationale Diner Cadeau kan je besteden bij ruim 3000 restaurants in Nederland. Er zit altijd wel een aangesloten restaurant in je omgeving, maar natuurlijk kan je er ook op uit met de dinercheque van Diner Cadeau! Ooit al wel eens gegeten in een kasteel? Diner Cadeau is bij verschillende prachtige kastelen te besteden. Hou je meer van zon, zee en strand? Bij de aangesloten strandtenten geniet je van een lekker zomers diner. Ben je een echte fijnproever? Diner Cadeau kan ook besteed worden bij een selectie aan sterrenrestaurants waar je je smaakpapillen kan laten prikkelen! Diner Cadeau is een veelzijdig culinaire dinercheque met voor iedere smaak wat wils."
    },
    doubler: undefined
};

export default Doubler;
