import PropTypes from "prop-types";
import Link from "next/link";

import { MerchantSliderItemApiPropTypes } from "types/MerchantSliderItem.propTypes";
import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import MerchantSlider from "components/shared/merchantSlider/MerchantSlider";

import Finder from "./finder/Finder";

import {
    Wrapper,
    Container,
    Intro,
    Title,
    Description,
    StyledButton
} from "./Merchants.styled";

function getSlidesToShow(desktopView, desktopLargeView) {
    if (desktopLargeView) {
        return 2.7;
    }
    if (desktopView) {
        return 1.8;
    }

    return 1.6;
}

const restaurantsLink = (
    <Link href="/restaurants/" passHref prefetch={false}>
        <StyledButton element="a" variant="outline">
            alle restaurants bekijken
        </StyledButton>
    </Link>
);

function Merchants({ title, description, merchantsTitle, merchants }) {
    const { mobileView, desktopView, desktopLargeView } =
        useMediaQueryContext();
    const slidesToShow = getSlidesToShow(desktopView, desktopLargeView);

    return (
        <Wrapper>
            <Container>
                <Intro>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    {!mobileView && restaurantsLink}
                    <Finder />
                    {mobileView && restaurantsLink}
                </Intro>
                <MerchantSlider
                    title={merchantsTitle}
                    merchants={merchants}
                    filter={{ promoted: true }}
                    limit={9}
                    slidesToShow={slidesToShow}
                />
            </Container>
        </Wrapper>
    );
}

Merchants.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    merchantsTitle: PropTypes.string,
    merchants: PropTypes.arrayOf(MerchantSliderItemApiPropTypes)
};

Merchants.defaultProps = {
    title: "Restaurants",
    description:
        "Onze Nationale Diner Cadeaukaart is te besteden bij meer dan 3000 restaurants. Zo is er altijd één bij jou in de buurt.",
    merchantsTitle: "Meest populaire restaurants",
    merchants: []
};

export default Merchants;
