import styled from "styled-components";

import media from "constants/media";

import IconArrowRight from "components/ui/icons/IconArrowRight";

export const ArrowIcon = styled(IconArrowRight)`
    position: absolute;
    right: 20px;
    bottom: 20px;

    transition: all var(--animation-duration-default) ease;
`;

export const Title = styled.h4`
    margin: 0 0 3px;
    padding: 0;
    overflow: hidden;

    color: var(--color-black);
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;

    transition: all var(--animation-duration-default) ease;

    ${media.mdUp} {
        font-size: var(--font-size-h5);
        line-height: var(--line-height-h5);
    }
`;

export const Content = styled.div`
    position: relative;

    padding: 20px;
`;

export const ImageContainer = styled.div`
    position: relative;

    width: 100%;

    background-color: var(--color-purple-90);
    aspect-ratio: 2;
`;

export const Theme = styled.p`
    margin: 0 0 15px;
    overflow: hidden;

    color: var(--color-black);
    font-size: 13px;
    line-height: var(--line-height-body);
    white-space: nowrap;
    text-overflow: ellipsis;

    ${media.mdUp} {
        font-size: var(--font-size-body);
    }
`;

export const Location = styled.p`
    margin: 15px 0 0;
    overflow: hidden;

    color: var(--color-gray-40);
    font-weight: normal;
    font-size: 13px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Container = styled.article`
    display: block;
    width: 100%;
    overflow: hidden;

    border-radius: var(--border-radius-small);
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.05);

    transition: all var(--animation-duration-default) ease;

    :hover,
    :focus {
        box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.02);

        ${Title} {
            color: var(--color-purple);
        }

        ${ArrowIcon} {
            transform: translateX(5px);

            fill: var(--color-purple-30);
        }
    }

    ${media.mdUp} {
        box-shadow: 0 18px 18px 0 rgba(0, 0, 0, 0.1);

        :hover,
        :focus {
            box-shadow: 0 35px 28px -10px rgba(0, 0, 0, 0.15);
        }
    }
`;
